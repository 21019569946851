import {createApp} from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/base.css";
import "./assets/css/toaster.css";
import "./assets/css/index.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import {createPinia} from "pinia";
import {VueQueryPlugin} from "@tanstack/vue-query";
import 'vue3-toastify/dist/index.css';
import Antd, {DatePicker} from 'ant-design-vue';
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import VCalendar from 'v-calendar';
import VDatePicker from 'v-calendar';
import 'v-calendar/style.css';
import 'ant-design-vue/dist/reset.css';
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const utc = require('dayjs/plugin/utc');
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const pinia = createPinia()
const app = createApp(App);
app.component("QuillEditor", QuillEditor)

// Utiliser les extensions
app.use(store);
app.use(DatePicker);
app.use(router);
app.use(pinia);
app.use(Antd);
app.use(VueQueryPlugin)
app.use(ArgonDashboard);
// Use plugin with optional defaults
app.use(VCalendar, {})
app.use(VDatePicker, {})
app.mount("#app");
