<script setup>
import {computed, ref, watch} from "vue";
import {message} from 'ant-design-vue';
import AntImagePicker from "./AntImagePicker.vue";
import {createProduct, setImage} from "../../services-apis/products/products-request";
import Loading from "./Loading.vue";
import {productStore} from "../../store/pinia-stores/products-store";
import {useProductCategories, useProducts} from "../../services-apis/products/hooks.products";
import {getDownloadURL, ref as refCloud, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../services-apis/firebase/firebase";
import router from "../../router";
import CreateProductCategoryModal from "../modals/CreateProductCategoryModal.vue";
import formatCurrency from "../../middlewares/formatCurrency";
import {customToolbar} from "../../constants";

// Les catégories des produits
const {categories} = useProductCategories()

const pstore = productStore()
const [messageApi, contextHolder] = message.useMessage();
// Après création ce produit sera directement affecté comme produit courant
const product = computed(() => pstore.current)

// Champs du formulaire
const name = ref('');
const description = ref('');
const pricePerUnit = ref(''); // Initialiser avec 0 pour les calculs
const nombreUniteIndividuelles = ref('');
const discountPercentage = ref('');
const discountPrice = ref('');
const testDiscountPrice = ref('');
const writingDiscountPrice = ref(false); // Vérifie pour émettre un événement que nous sommes en train d'écrire dans le champs: "Prix de reduction"
const nombreDeCartonDisponibles = ref('');
const nombreUniteParCarton = ref('');
const pricePerCarton = ref('');
// Traitement des fichiers
const selectedFiles = ref([]);
const progressUpload = ref([]);
const categoriesUsed = ref([]);

// Fonction pour ajouter une catégorie à la liste des catégories utilisées
const addCategoryIntoUsed = (category) => {
  if (!categoriesUsed.value.includes(category)) {
    categoriesUsed.value.push(category);
    messageApi.success(`Catégorie "${category.name}" ajoutée.`);
  } else {
    messageApi.info(`La catégorie "${category.name}" est déjà sélectionnée.`);
  }
};

// Fonction pour retirer une catégorie de la liste des catégories utilisées
const removeCategoryFromUsed = (category) => {
  const index = categoriesUsed.value.findIndex((cat) => cat.id === category.id);
  if (index !== -1) {
    categoriesUsed.value.splice(index, 1);
    messageApi.success(`Catégorie "${category.name}" retirée.`);
  } else {
    messageApi.info(`La catégorie "${category.name}" n'est pas sélectionnée.`);
  }
};

// Computed pour vérifier si une catégorie est utilisée
const isCategoryUsed = (category) => {
  return computed(() => categoriesUsed.value.some((cat) => cat.id === category.id));
};

// Vérifie si on enregistre un produit en promotion directement
const enablePromotion = ref(false);
// Vérifie si on enregistre un produit avec un stock par lot (cartons)
const enabledLots = ref(false);

const {refetch} = useProducts()

// Une fois qu'une image est ajoutée
const pickerSelectedFiles = (files) => {
  // Valeur définitive
  selectedFiles.value = files
}

// Sauvegarder l'url de l'image dans l'api CA
const saveImageUrlsToAPI = (uploadFileUrls) => {
  let productId = Number(product.value?.id)
  setImage(productId, {urls: uploadFileUrls}, () => {
    messageApi.info(`Images enregistrées`)
    router.push('/shop')
  }, () => {
    messageApi.error("Erreur de sauvegarde de l'image")
  })
}

/*
* Sauvegarde des fichiers
* */
const saveFiles = async (images) => {


  const uploadTasks = images.map((image) => {
    return new Promise((resolve, reject) => {
      try {
        if (!image || !image.originFileObj) {
          throw new Error("Fichier invalide ou absent");
        }

        const fileBlob = image.originFileObj; // Extraire le fichier en tant qu'objet Blob
        const fileName = `${Date.now()}_${image.name}`; // Générer un nom unique pour le fichier
        const fileType = image.type || "application/octet-stream"; // Type MIME

        // Référence dans Firebase Storage
        const refFile = refCloud(storage, `products-images/${fileName}`);

        // Initialiser le téléchargement
        const uploadTask = uploadBytesResumable(refFile, fileBlob, {
          contentType: fileType,
        });

        // Suivre la progression
        uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = parseFloat(
                  ((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(2)
              );
              console.log(`Progression du fichier ${fileName}: ${progress}%`);

              switch (snapshot.state) {
                case "paused":
                  console.log(`Upload du fichier ${fileName} en pause`);
                  break;
                case "running":
                  console.log(`Upload du fichier ${fileName} en cours`);
                  break;
              }
            },
            (error) => {
              console.error(`Erreur lors de l'upload du fichier ${fileName} :`, error);
              reject(error);
            },
            async () => {
              // Une fois terminé, obtenir l'URL de téléchargement
              try {
                const downloadURL = await getDownloadURL(refFile);
                console.log(`Upload terminé pour ${fileName}, URL : ${downloadURL}`);
                resolve(downloadURL);
              } catch (error) {
                console.error(`Erreur lors de la récupération de l'URL pour ${fileName} :`, error);
                reject(error);
              }
            }
        );
      } catch (error) {
        reject(error);
      }
    });
  });

  try {
    // Attendre que tous les fichiers soient téléchargés
    const urls = await Promise.all(uploadTasks);

    // Appeler l'API pour sauvegarder les URLs
    await saveImageUrlsToAPI(urls);
    console.log("Toutes les images ont été uploadées et sauvegardées avec succès !");

  } catch (error) {
    console.error("Erreur lors de l'upload des images :", error);
  }
};

function callbackCreate() {
  messageApi.info('Produit ajouté')
  refetch()
  saveFiles(selectedFiles.value)

  // Rediriger directement vers l'écran de la boutique au cas où aucune image n'est ajoutée
  if (selectedFiles.value?.length === 0)
    router.push('/shop')
}

// Fonction pour soumettre le formulaire de création
const submitData = () => {

  // Validation des champs obligatoires
  if (!name.value || !description.value || !pricePerUnit.value || (!nombreUniteIndividuelles.value && !enabledLots.value)) {
    messageApi.error("Veuillez remplir tous les champs obligatoires. (*)");
    return;
  }

  // Validation des champs liés à la promotion
  if (enablePromotion.value && !discountPercentage.value) {
    messageApi.error("Veuillez indiquer le pourcentage réduction.");
    return;
  }

  // Validation des lots si activé
  if (enabledLots.value) {
    if (!nombreDeCartonDisponibles.value || !pricePerCarton.value || !nombreUniteParCarton.value) {
      messageApi.error("Veuillez indiquer la quantité et le prix d'un lot; mais aussi le nombre d'unité par lot");
      return;
    }
  }

  // Creation d'un produit
  createProduct({
    "name": name.value,
    "description": description.value,
    "pricePerUnit": Number(pricePerUnit.value),
    "pricePerCarton": Number(pricePerCarton.value),
    "cartonQuantity": Number(nombreDeCartonDisponibles.value),
    "unitsPerCarton": Number(nombreUniteParCarton.value),
    "individualUnits": Number(nombreUniteIndividuelles.value),
    "enablePromotion": enablePromotion.value ? 'OUI' : 'NON',
    "discountPercent": discountPercentage.value || Number(0),
    "quantityType": enabledLots.value ? 'CARTON' : 'INDIVIDUAL',
    "categoryIds": categoriesUsed.value.map((item) => item.id),
  }, callbackCreate, (msg) => {
    messageApi.error(msg || 'Création échouée')
  })
};

// Fonction pour calculer le prix après réduction
const calculerPrixApresReduction = () => {
  if (pricePerUnit.value && discountPercentage.value !== null) {
    // Limiter le pourcentage de réduction à -100% et 100%
    if (discountPercentage.value > 100) {
      discountPercentage.value = String(100);
    } else if (discountPercentage.value < -100) {
      discountPercentage.value = String(-100);
    }
    // Calculer le prix après réduction
    discountPrice.value = String(pricePerUnit.value * (1 - discountPercentage.value / 100));
  }
};

// Après avoir fini d'écrire le prix de la reduction pour recalculté les %
const writeFinished = (e) => {
  discountPrice.value = e.target.value
  calculerPourcentageReduction()
}

// Fonction pour calculer le pourcentage de réduction en fonction du prix après réduction
const calculerPourcentageReduction = () => {
  if (pricePerUnit.value > 0 && discountPrice.value !== null) {
    discountPercentage.value = String(((pricePerUnit.value - discountPrice.value) / pricePerUnit.value) * 100);

    // Limiter la valeur du pourcentage entre -100 et 100
    if (discountPercentage.value > 100) {
      discountPercentage.value = String(100);
    } else if (discountPercentage.value < -100) {
      discountPercentage.value = String(-100);
    }
    writingDiscountPrice.value = false
  }
};

// Watchers pour ajuster les valeurs
watch([discountPercentage, pricePerUnit], () => {
  if (enablePromotion.value && discountPercentage.value !== null) {
    calculerPrixApresReduction();
  }
});

// S'assurer que l'input % pourcentage ne dépasse jamais 100 % ou -100%
watch(discountPercentage, () => {
  if (discountPercentage.value > 100) {
    discountPercentage.value = String(100)
  } else if (discountPercentage.value < -100) {
    discountPercentage.value = String(-100);
  }
});
</script>

<template>
  <contextHolder/>

  <form :class="pstore.isLoading ? 'form-pointer':''" action="" class="card mb-4 border-radius-md"
        enctype="multipart/form-data" method="post"
        @submit.prevent="submitData">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6 class="font-weight-bold">Nouveau produit</h6>
      </div>
    </div>

    <div class="container my-4">
      <div class="d-flex flex-row flex-wrap gap-1">
        <!-- Section Informations initiales avec bordure -->
        <div class="section-border col-md-6">
          <h4 class="font-weight-bold mb-2">Informations initiales</h4>
          <div class="d-grid col-10">
            <h4 class="text-sm my-2 font-weight-bold">Nom du produit <span class="text-danger">*</span></h4>
            <a-input v-model:value="name" placeholder="Ex: Générateur 65 Volts / Voiture Essence"/>
          </div>

          <div class="d-grid col-11 my-2">
            <h4 class="text-sm my-2 font-weight-bold">Décrivez le produit en quelques lignes <span
                class="text-danger">*</span></h4>
            <quill-editor
                v-model:content="description"
                :toolbar="customToolbar"
                class="border-0 form-control bg-gray-100 px-0"
                content-type="html"
                placeholder="Donnez la description"
                style="font-size: 12px;" theme="snow"/>


          </div>

          <div class="d-grid col-10">
            <h4 class="text-sm my-2 font-weight-bold">Prix fixe du produit (prix unitaire) <span
                class="text-danger">*</span></h4>
            <p class="text-sm text-secondary">
              Ce prix est le montant exact d'une unité de ce produit !
              <span class="text-success">Une unité (pièce) de ce produit coûtera {{
                  formatCurrency(Number(pricePerUnit))
                }} $</span>
            </p>
            <a-input-number v-model:value="pricePerUnit" :disabled="writingDiscountPrice" class="w-100"
                            placeholder="Prix par unité du produit (Ex: 1 produit X coûtera 10 $)"/>
          </div>

          <div class="d-grid col-10">
            <p class="text-sm text-danger text-bold mt-3">
              Ce produit est-il en promotion ?
              <a-button type="link" @click="enablePromotion=!enablePromotion">{{
                  enablePromotion ? "Oui" : "Non"
                }}
              </a-button>
            </p>
          </div>

          <div v-if="enablePromotion" class="d-grid col-10">
            <p class="text-success text-sm">Pourcentage ou coût de réduction <span class="text-danger">*</span></p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="discountPercentage" :disabled="writingDiscountPrice" class="w-25"
                              placeholder="Le %"/>
              <!-- Champ pour saisir le prix après réduction -->
              <!--
                Le champ affiche la valeur de discountPrice tant que l'utilisateur n'est pas en train d'écrire.
                Une fois que l'utilisateur commence à écrire, il affiche testDiscountPrice à la place.

                Sachat que lorsqu'un entre en focus dans le input nous affectons direction le prix de la discountPrice dans le prix du testDisc...
              -->
              <a-input-number
                  :value="!writingDiscountPrice ? discountPrice : testDiscountPrice"
                  class="w-50"
                  placeholder="Prix après réduction"
                  @input="writingDiscountPrice = true"
                  @blur.stop="writeFinished"
                  @blur.capture="testDiscountPrice = discountPrice"
              />


            </div>
            <p class="text-xs my-2">
              Le prix de réduction est calculé en faisant le rapport entre le prix fixe et le % de réduction.
            </p>
          </div>
        </div>

        <!-- Section Stock avec bordure -->
        <div class="section-border w-45">
          <h4 class="font-weight-bold mb-2">Stock</h4>
          <div class="d-grid col-10">
            <p class="text-sm text-danger text-bold mt-3">
              Ce produit est-il susceptible d'être vendu en gros (par lot, carton, prix de gros...) ?
              <a-button type="link" @click="enabledLots=!enabledLots">{{ enabledLots ? "Oui" : "Non" }}</a-button>
            </p>
          </div>

          <div v-if="enabledLots" class="d-grid col-10">
            <strong class="text-success text-sm mb-1">Quantité & prix par lots (carton) <span
                class="text-danger">*</span></strong>
            <p class="text-sm text-secondary">
              Nbre des lots • <span class="text-dark">Nombre des lots disponibles.</span><br>
              Ex: 5 cartons(lots) des savons disponibles.
            </p>
            <p class="text-sm text-secondary">
              Unités par lot • <span class="text-dark">Le nombre d'unité de produit contenu dans un lot (carton).</span><br>
              Ex: 1 carton de 20 savons.
            </p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="nombreDeCartonDisponibles" class="w-45" placeholder="Nbre des lots"/>
              <a-input-number v-model:value="nombreUniteParCarton" class="w-45" placeholder="Unités par lot"/>
            </div>
            <p class="text-sm text-secondary">
              Prix par lot • <span class="text-dark">Le prix d'1 lot (carton).</span><br>
              Ex: 1 carton de 20 savons vaut 20 $.
            </p>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="pricePerCarton" class="w-90" placeholder="Prix par lot (par carton)"/>
            </div>
            <div class="col-lg-12 mt-2">

              <p class="text-sm text-secondary">
                Ces informations concernent des produits tels que des clés USB à vendre en gros, des cartons des savons
                etc ...
              </p>
            </div>

          </div>

          <!-- Quantité en stock (Unité par Unité) -->
          <div class="d-grid col-10">
            <strong class="text-success text-sm mb-1 mt-3">Quantité de marchandises
              {{ enabledLots ? "(Hors carton)" : '' }}
              <span v-if="!enabledLots" class="text-danger">*</span></strong>
            <div class="d-flex flex-row align-items-center flex-wrap gap-1">
              <a-input-number v-model:value="nombreUniteIndividuelles" class="w-75"
                              placeholder="Quantité (nombre d'unités en stock)"/>
            </div>
            <p class="text-xs my-2" style="line-height: 20px;">
              Cette quantité est sauvegardée dans le système afin d'indiquer aux clients lorsqu'un produit est
              disponible, au bord de la rupture ou en rupture de stock.
            </p>
          </div>

          <h4 class="font-weight-bold my-2">Catégories</h4>
          <div class="d-grid col-10">
            <p class="text-sm text-gray-400">
              Categorisez ce produit en une ou plusieurs catégories systèmes
            </p>

            <!-- Aucune catégorie existante n'a été créé en avance -->
            <div class="center">
              <p v-if="categories?.length === 0" class="text-xs text-gray-400">
                -- Aucune catégorie existante --
              </p>
            </div>

            <div class="d-flex flex-row flex-wrap gap-1 justify-content-start align-items-start">
              <!--  Liste des catégories-->
              <div v-for="category in categories" :key="category?.id"
                   class="bg-white shadow-none border-none p-2 cursor-pointer border border-radius-xl font-weight-normal text-xs"
                   @click="isCategoryUsed(category).value? removeCategoryFromUsed(category):addCategoryIntoUsed(category)">
                <i v-if="isCategoryUsed(category).value"
                   class="fas fa-check bg-link-blue text-white border-radius-xl"></i>
                {{ category?.name }}
              </div>

              <!-- Nouvelle catégorie de produit-->
              <create-product-category-modal>
                <a-button class="text-xs color-text-blue-ca border border-radius-lg p-2 mx-1" type="link"><i
                    class="fas fa-plus px-1"></i>
                </a-button>
              </create-product-category-modal>

            </div>
          </div>

        </div>

        <div class="section-border col-md-12">

          <h6 class="mb-3 text-sm">Selectionner les images</h6>

          <!-- Selecteur d'images -->
          <ant-image-picker
              :maxCount="3"
              @update:files="pickerSelectedFiles"
          />
          <a-progress v-if="progressUpload>0" :percent="progressUpload" :steps="10" status="active"/>

        </div>


        <!--        Le chargement-->
        <div v-if="pstore.isLoading" class="col-md-12">
          <Loading/>
        </div>

        <div class="d-flex flex-row flex-wrap w-100 justify-content-end my-2">
          <a-button class="bg-danger-soft text-danger text-bold mx-2 text-sm" type="link">Annuler
          </a-button>
          <button class="bg-link-blue text-white text-bold  border-radius-sm mx-2 text-xs px-2 border-0"
                  type="submit">Sauvegarder
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped>
.section-border {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.form-pointer {
  pointer-events: none;
  opacity: .3;
}
</style>
