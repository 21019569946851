<script setup>
import {computed} from "vue";

const props = defineProps(['params']) // On passe les paramètres Aggrid
const description = computed(() => props.params.value || 'Pas de nom')

</script>

<template>
  <div v-html="description"></div>
</template>

<style scoped>
</style>
