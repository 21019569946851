<script setup>
import {ref} from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {AgGridVue} from "ag-grid-vue3";
import {useProducts} from "../../services-apis/products/hooks.products";
import TitleProductRender from "./aggrid-templates/TitleProductRender.vue";
import {AG_GRID_LOCALE_FR} from '@ag-grid-community/locale';
import DescriptionProductRender from "./aggrid-templates/DescriptionProductRender.vue";

const search = ref('')
const {products} = useProducts()

// Column Definitions with Grouping
const colDefs = ref([
  {
    headerName: "Informations de base", // Grouping for basic information
    children: [
      {headerName: "Nom du Produit", field: "name", cellRenderer: TitleProductRender, flex: 1, minWidth: 400},
      {headerName: "Détails", field: "description", cellRenderer: DescriptionProductRender, width: 350, maxWidth: 700},
      {headerName: "Prix Unitaire", field: "pricePerUnit", minWidth: 100, width: 170, maxWidth: 300},
      {headerName: "Prix par Carton", field: "pricePerCarton", minWidth: 100, width: 170, maxWidth: 300},
    ]
  },
  {
    headerName: "Stock", // Grouping for stock information
    children: [
      {headerName: "Quantité Des Cartons", field: "cartonQuantity", minWidth: 100, width: 170, maxWidth: 300},
      {headerName: "Unités par Carton", field: "unitsPerCarton", minWidth: 100, width: 170, maxWidth: 300},
      {headerName: "Unités Individuelles", field: "individualUnits", minWidth: 100, width: 170, maxWidth: 300},
      {headerName: "Pourcentage de Remise", field: "discountPercent", minWidth: 100, width: 170, maxWidth: 300},
      {
        headerName: "Date de Création",
        field: "createdAt",
        type: 'dateColumn',
        valueFormatter: (params) => new Date(params.value).toLocaleDateString()
      },
    ]
  }
]);

const pagination = true;
const paginationPageSize = 10;
const paginationPageSizeSelector = [10, 20, 50, 100];

</script>

<template>
  <div class="card mb-4 border-radius-md">
    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Produits ajoutés</h6>
      </div>

      <a-input
          v-model:value="search"
          class="m-3" placeholder="Recherche d'un produit"
          style="width:18vw;"
          type="text"
      />
    </div>

    <div class="card-body pt-0 pb-2 px-3">
      <ag-grid-vue
          :columnDefs="colDefs"
          :localText="AG_GRID_LOCALE_FR"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
          :paginationPageSizeSelector="paginationPageSizeSelector"
          :row-height="45"
          :rowData="products"
          class="ag-theme-quartz"
          style="height: 500px"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
